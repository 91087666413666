import React, { useEffect } from "react";
import { IonPage, IonItem, IonContent } from "@ionic/react";
import { useSelector } from "react-redux";
import { requestPosts } from "../05_redux/PostSlice";
import store, { RootState } from "../05_redux/store";
import { UserType } from "../app/types";
import Post from "../03_organisms/Post/Post";

import { PostEntity } from "../06_utils/db/entity/postEntity";
import { useTranslation } from "react-i18next";
import TopBar from "../01_atoms/TopBar";
import classes from "./Wall.module.scss";

const Wall = ({ selectedKid }: Props) => {
  const { t } = useTranslation();
  const dispatch = store.dispatch;
  const user = useSelector((root: RootState) => root.statusSlice.status.user);
  let posts: PostEntity[] = useSelector((root: RootState) => root.postSlice.postStore.posts);

  const editable = user.type === "kid";
  const isTerap = user.type === "terap";

  useEffect(() => {
    dispatch(requestPosts(selectedKid));
  }, [dispatch, selectedKid]);

  return (
    <IonPage className={classes.WallPage}>
      <TopBar title={t("wall.title")} />
      <IonContent>
        {posts.map((post, index) => (
          <IonItem key={index} lines="none" style={{ marginBottom: "20px" }}>
            <Post post={post} editable={editable} terapist={isTerap} />
          </IonItem>
        ))}
      </IonContent>
    </IonPage>
  );
};

interface Props {
  selectedKid?: UserType;
}

export default Wall;
