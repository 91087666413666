import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {IonTabs,IonTabBar,IonTabButton,IonIcon, IonLabel,IonFab,IonFabButton,IonTab} from "@ionic/react";
import { home as homeIcon, settings as settingsIcon, add as addIcon } from "ionicons/icons";
import Wall from "./Wall";
import HomeWork from "./HomeWorks";
import { useNavigate } from "react-router-dom";

const Main = ({ idx }: Props) => {
  const [activeTab, setActiveTab] = useState<"wall" | "homework">(
    idx === 0 ? "wall" : "homework"
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const addBtnAction = () => {
    if (activeTab === "homework") {
      navigate("/newhomework");
    } else {
      navigate("/newpost");
    }
  };

  return (
    <div>
      <IonTabs>
        <IonTab tab="wall">
          {activeTab === "wall" && <Wall />}
        </IonTab>
        <IonTab tab="homework">
          {activeTab === "homework" && <HomeWork />}
        </IonTab>

        <IonTabBar
          slot="bottom"
          onIonTabsWillChange={(event) => {
            const selectedTab = event.detail.tab;
            setActiveTab(selectedTab as "wall" | "homework");
          }}
        >
          <IonTabButton tab="wall">
            <IonIcon icon={homeIcon} />
            <IonLabel>{t("menu.wall")}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="homework">
            <IonIcon icon={settingsIcon} />
            <IonLabel>{t("menu.homework")}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>

      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton id="goto-add-action-btn" onClick={addBtnAction}>
          <IonIcon icon={addIcon} />
        </IonFabButton>
      </IonFab>
    </div>
  );
};

interface Props {
  idx: number;
}

export default Main;
