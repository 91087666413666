import React from 'react';
import classes from './Comment.module.scss';
import { useTranslation } from 'react-i18next';
import { IonTextarea } from '@ionic/react';

const Comment = ({ comment, setComment }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classes.commentContainer}>
            <IonTextarea
                id="comment-new-post--input"
                value={comment}
                rows={10}
                placeholder={t('newPost.commentPlaceholder')}
                onIonChange={(event) => setComment(event.detail.value)}
                className={classes.textarea}
            />
        </div>
    );
};

interface Props {
    comment: string;
    setComment: Function;
}

export default Comment;