import React from 'react';
import { IonPage, IonModal} from '@ionic/react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../05_redux/store';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';

const Layout: React.FC<Props> = ({ menu, disableMenu, user }) => {
  const status = useSelector((root: RootState) => root.statusSlice.status);

  return (
    <IonPage className='layout-bg-color'>
      <Outlet />
      <IonModal isOpen={status.loading} backdropDismiss={false}>
          <p>Loading...</p>
      </IonModal>

      <AddToHomeScreen 
        skipFirstVisit={false} 
        cookie={{ name: "slonie-install-prompt" }} 
      />
    </IonPage>
  );
};

interface Props {
  menu: AppLink[];
  disableMenu: boolean;
  user?: any;
}

interface AppLink {
  label: string;
  href: string;
  icon: React.ReactElement;
}

export default Layout;
