import React, { useEffect, useState, useRef } from 'react';
import classes from './HomeworkAssign.module.scss';
import store, { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity';
import { useSelector } from 'react-redux';
import { requestHomeworks } from '../../05_redux/HomeworkSlice';

const HomeworkAssign = ({ initialHomework, setHomework }: Props) => {
    const dispatch = store.dispatch;
    const homeworks: HomeworkEntity[] = useSelector((root: RootState) => root.homeworkSlice.homeworkStore.homeworks);
    const [homework, setHomeworkState] = useState(initialHomework);
    const selectRef = useRef<HTMLSelectElement | null>(null);

    useEffect(() => {
        dispatch(requestHomeworks());
    }, [dispatch]);

    useEffect(() => {
        if (selectRef.current) {
            const element = selectRef.current;
            element.classList.add('modifier-homework');
        }
    }, []);

    const handleHomeworkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedHomework = event.target.value;
        console.log('Selected Homework:', selectedHomework);
        setHomeworkState(selectedHomework);
        setHomework(selectedHomework);
    };

    useEffect(() => {
        console.log('Current Homework:', homework);
    }, [homework]);

    useEffect(() => {
        setHomeworkState(initialHomework);
    }, [initialHomework]);

    return (
        <div className={classes.commentContainer}>
            <div className={classes.homeworkAssignContainer}>
                <select
                    ref={selectRef}
                    id="homework-select"
                    className={`${classes.select}`}
                    value={homework}
                    onChange={handleHomeworkChange}
                >
                    <option value="">-</option>
                    {homeworks.map((h) => (
                        <option key={h.id} value={h.id} className={classes.homeworkAssignOption}>
                            {h.title}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

interface Props {
    initialHomework: string;
    setHomework: (value: string) => void;
}

export default HomeworkAssign;