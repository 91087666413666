import 'reflect-metadata';
import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './05_redux/store';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SplashScreen } from '@capacitor/splash-screen';// Webpack CSS import
//import 'onsenui/css/onsenui.css';
//import 'onsenui/css/onsen-css-components.css';
import { defineCustomElements as jeepSqlite, applyPolyfills, JSX as LocalJSX  } from "jeep-sqlite/loader";
import { Capacitor } from '@capacitor/core';
import sqliteConnection from './06_utils/db/database';
import { CapacitorSQLite } from '@capacitor-community/sqlite';
import PostDataSource from './06_utils/db/data-sources/PostDataSource';
import HomeworkDataSource from './06_utils/db/data-sources/HomeworkDataSource';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

await SplashScreen.show({
  showDuration: 2000,
  autoHide: true,
});

// window.addEventListener('myCustomEvent', (resolve, reject, args) => {
//   LocalNotifications.schedule({
//     notifications:[
//         {
//         id: 11,
//         title: "test title",
//         body: "test body",
//         largeBody: " test largebody",
//         summaryText: "test summary text"
//       }
//     ]
//   })
//   resolve();
// });

if ('serviceWorker' in navigator) {
  setTimeout(()=>{
    navigator.serviceWorker.register('/sw.js', { scope: '/' })
      .then((registration) => {
          const data = {
              type: 'CACHE_URLS',
              payload: [
                  window.location.href,
                  ...performance.getEntriesByType('resource').map((r) => r.name)
              ]
          };
          registration?.installing?.postMessage(data);
          console.log('SW registration Success.')
      })
      .catch((err) => console.log('SW registration FAIL:', err));
  },5000)
}


type StencilToReact<T> = {
  [P in keyof T]?: T[P] & Omit<HTMLAttributes<Element>, 'className'> & {
    class?: string;
  };
} ;

declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {
    }
  }
}

applyPolyfills().then(() => {
  jeepSqlite(window);
});

const platform = Capacitor.getPlatform();

try {

  if (platform === "web") {
    const jeepEl = document.createElement("jeep-sqlite");
    document.body.appendChild(jeepEl);
    await customElements.whenDefined('jeep-sqlite');
    await sqliteConnection.initWebStore();
  }

  // when using Capacitor, you might want to close existing connections,
  // otherwise new connections will fail when using dev-live-reload
  // see https://github.com/capacitor-community/sqlite/issues/106
  await CapacitorSQLite.checkConnectionsConsistency({
    dbNames: [], // i.e. "i expect no connections to be open"
    openModes: [],
  }).catch((e) => {
    // the plugin throws an error when closing connections. we can ignore
    // that since it is expected behaviour
    console.log(e);
    return {};
  });

  for (const connection of [PostDataSource, HomeworkDataSource]) {
    if (!connection.isInitialized) {
      await connection.initialize();
    }
    await connection.runMigrations();
  }

  if (platform === 'web') {
    // save the database from memory to store
    await sqliteConnection.saveToStore('slonieDb');
  }


  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );

  // Call the element loader after the app has been rendered the first time
  defineCustomElements(window);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

} catch (err) {
  console.log(`Error: ${err}`);
  throw new Error(`Error: ${err}`)
}
