import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, IonList, IonItem} from '@ionic/react';
import { useSelector} from 'react-redux';
import { requestHomeworks } from '../05_redux/HomeworkSlice';
import { HomeworkEntity } from '../06_utils/db/entity/homeworkEntity ';
import Homework from '../03_organisms/Homework/Homework';
import { useTranslation } from 'react-i18next';
import TopBarHomework from '../01_atoms/TopBarHomework';
import Separator from '../01_atoms/Separator';
import store, { RootState } from '../05_redux/store';
import classes from "./HomeWorks.module.scss";

const HomeWorks = () => {
  const dispatch = store.dispatch;
  const { t } = useTranslation();
  const selectedKid = useSelector((root: RootState) => root.statusSlice.status.selectedKid);

  const homeworks: HomeworkEntity[] = useSelector((root: RootState) => root.homeworkSlice.homeworkStore.homeworks);
  const homeworksToDO = homeworks.filter((h) => !h.archived);
  const homeworksDone = homeworks.filter((h) => h.archived);

  useEffect(() => {
    dispatch(requestHomeworks(selectedKid));
  }, [dispatch, selectedKid]);

  const [visibleToDO, setVisibleToDO] = useState(10);
  const [visibleDone, setVisibleDone] = useState(10);


  return (
    <IonPage className={classes.HomeWorksPage}>
      <TopBarHomework title={t('homeworks.title')}/>
        <IonContent>
          <IonList>
            {homeworksToDO.slice(0, visibleToDO).map((homework, index) => (
              <IonItem key={index}>
                <Homework homework={homework} />
              </IonItem>
            ))}
          
          </IonList>
          {homeworksDone.length > 0 && (
            <>
              <Separator title={t('homeworks.separator')} />
              <IonList>
                {homeworksDone.slice(0, visibleDone).map((homework, index) => (
                  <IonItem key={index}>
                    <Homework homework={homework} />
                  </IonItem>
                ))}
              
              </IonList>
            </>
          )}
        </IonContent>
    </IonPage>
  );
};

export default HomeWorks;
