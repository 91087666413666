import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity ';
import classes from './HomeworkDetail.module.scss';
import Wave from '../../01_atoms/Wave';
import TopBar from '../../01_atoms/TopBar';
import Fab from '../../01_atoms/Fab';
import { FaFlagCheckered, FaPlus } from 'react-icons/fa';
import Post from '../Post/Post';
import { useTranslation } from 'react-i18next';
import { IonCol, IonPage, IonRow } from '@ionic/react';

const HomeworkDetail = () =>{
    const { t } = useTranslation()
    const navigate = useNavigate();
    const homeworks:HomeworkEntity[] = useSelector((root:RootState) => root.homeworkSlice.homeworkStore.homeworks);
    const { id } = useParams();

    const homework = homeworks.find(h => h.id === Number(id));

    const back = () => {
        navigate('/homework/');
    }

    const createNewPost = () => {
        navigate('/newpost/'+homework?.id);
    }

    const endHomework = () => {
        navigate('/endhomework/'+homework?.id);
    }

    return(
        <IonPage>
            <TopBar fun={back} title={homework?.title as string} /> 
            <Wave invert/>
            <div className={classes.HomeworkDetail}>
                <IonRow>
                    <IonCol className={classes.HomeworkDetailDescr}>
                        {homework?.descr}
                    </IonCol>
                </IonRow>
                <IonRow className={classes.HomeworkDetailPosts}>
                    <IonCol>
                        {homework?.post?.length === 0 && t("homeworkDetail.noPosts")}                    
                        {homework?.post?.map((pfh, i)=><Post key={i} post={pfh} editable={false}/>)}
                    </IonCol>
                </IonRow>
                { !homework?.archived && 
                    <IonRow className={classes.HomeworkDetailButtons}>
                        <IonCol className={classes.HomeworkDetailButton}>
                            <Fab 
                                id="goto-add-post-from-homework--btn" 
                                onClick={endHomework} 
                                title={t('homeworkDetail.closeHomework')} 
                                floating={false}
                                disabled={homework?.post?.length === 0}
                            >
                                <FaFlagCheckered/>
                            </Fab>
                        </IonCol>
                        <IonCol className={classes.HomeworkDetailButton}>
                            <Fab 
                                id="goto-add-post-from-homework--btn" 
                                onClick={createNewPost} 
                                title={t('homeworkDetail.addPost')} 
                                floating={false}
                            >
                                <FaPlus/>
                            </Fab>
                        </IonCol>
                    </IonRow>
                }
            </div>
        </IonPage>
    )
}

export default HomeworkDetail;