import React, { useCallback, useMemo, useState } from "react";
import classes from "./Emotion.module.scss";
import { useTranslation } from "react-i18next";
import { IonButton, IonCol, IonRange, IonRow } from "@ionic/react";
import SegmentBtn from "../../01_atoms/SegmentBtn";

const Emotion = ({ emotions, setEmotions }: Props) => {
    const { t } = useTranslation();
    const [idx, setIdx] = useState(0);

    const emotionsList = useMemo(
        () => ({
            pleasant: ["gratitude", "joy", "satisfaction", "peace", "love", "pride"],
            unpleasant: [
                "anger",
                "sadness",
                "shame",
                "fear",
                "jealousy",
                "irritation",
                "anxiety",
            ],
        }),
        []
    );

    const getEmotionLabel = (e) => {
        return e.split(":")[0];
    };

    const getEmotionValue = (e) => {
        return Number(e.split(":")[1]);
    };

    const setEmotionValue = (e, value) => {
        const newEmotions: string[] = [...emotions];
        const index = newEmotions.findIndex((ee) => ee === e);
        newEmotions[index] = getEmotionLabel(e) + ":" + value;
        setEmotions(newEmotions);
    };

    const toggleEmotion = useCallback(
        (e: string) => {
            let newEmotions: string[] = [];
            if (emotions.map((ee) => getEmotionLabel(ee)).includes(e)) {
                newEmotions = emotions.filter((ee) => getEmotionLabel(ee) !== e);
            } else if (emotions.length < 3) {
                newEmotions = [...emotions];
                newEmotions.push(e + ":50");
            } else {
                newEmotions = [...emotions];
            }
            setEmotions(newEmotions);
        },
        [emotions, setEmotions]
    );

    return (
        <div className={classes.CommentContainer}>
            <IonRow className={classes.EmotionTypeContainer}>
                <SegmentBtn
                    labels={[t("emotions.pleasant"), t("emotions.unpleasant")]}
                    idx={idx}
                    setIdx={setIdx}
                />
                <span className={classes.EmotionCounter}>({emotions.length} / 3)</span>
            </IonRow>

            {idx === 0 && (
                <IonRow className={classes.EmotionSelectContainer}>
                    {emotionsList.pleasant.map((e) => {
                        const modifier = emotions
                            .map((ee) => getEmotionLabel(ee))
                            .includes(e)
                            ? "solid"
                            : "outline";
                        return (
                            <IonCol className={classes.Emotion}>
                                <IonButton
                                    className={classes.EmotionBtn}
                                    fill={modifier}
                                    onClick={() => toggleEmotion(e)}
                                >
                                    {t("emotions." + getEmotionLabel(e))}
                                </IonButton>
                            </IonCol>
                        );
                    })}
                </IonRow>
            )}
            {idx === 1 && (
                <IonRow className={classes.EmotionSelectContainer}>
                    {emotionsList.unpleasant.map((e) => {
                        const modifier = emotions
                            .map((ee) => getEmotionLabel(ee))
                            .includes(e)
                            ? "solid"
                            : "outline";
                        return (
                            <IonCol className={classes.Emotion}>
                                <IonButton
                                    className={classes.EmotionBtn}
                                    fill={modifier}
                                    onClick={() => toggleEmotion(e)}
                                >
                                    {t("emotions." + getEmotionLabel(e))}
                                </IonButton>
                            </IonCol>
                        );
                    })}
                </IonRow>
            )}

            {emotions.map((e) => (
                <IonRow className={classes.EmotionRangeContainer}>
                    <div>
                        {t("emotions." + getEmotionLabel(e))} : {getEmotionValue(e)}%
                    </div>
                    <IonRange
                        className={classes.EmotionRage}
                        value={getEmotionValue(e)}
                        onIonChange={(event) =>
                            setEmotionValue(e, parseInt(event.detail.value.toString()))
                        }
                    />
                </IonRow>
            ))}
        </div>
    );
};

interface Props {
    emotions: string[];
    setEmotions: Function;
}

export default Emotion;
