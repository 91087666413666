import React, { useEffect, useState } from 'react';
import { FaChild, FaCommentMedical, FaHouseUser } from 'react-icons/fa';
import '../app/App.scss';
import store, { RootState } from '../05_redux/store';
import { useSelector } from 'react-redux';
import PinSelector from '../01_atoms/PinSelector';
import { login, loginOffline } from '../05_redux/StatusSlice';
import { useTranslation } from 'react-i18next';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';

const Login = () =>{
  const {t} = useTranslation()
  const status = useSelector((root:RootState)=>root.statusSlice.status);
  const user = status.user;
  const dispatch = store.dispatch;

  const [typedPin, setTypedPin] = useState([]as number[]);
  const [error, setError] = useState("")


  useEffect(()=>{
    if(typedPin.length >=4){
      dispatch(loginOffline(typedPin)).then(()=>{
        console.log("success")
      }).catch((e)=>{
        setError(t("login.pinIncorrect"))
      }).finally(()=>{
        setTypedPin([]);
      });
    }
  }, [typedPin, dispatch, t])
  
  if (status.isOnline && !user) {
    return (
      <section>
        <IonGrid>
          <IonRow>
            <IonCol className="text-center btn-padding-login">
              <IonButton className='btn-ion' id="login-kid1" expand="block" onClick={() => dispatch(login("Mariolka Rydowicz", "test"))}>
                <FaChild />
                LOGIN kid1
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="text-center btn-padding-login">
              <IonButton className='btn-ion' id="login-parent" expand="block" onClick={() => dispatch(login("Grzymisława Pipka", "parent"))}>
                <FaHouseUser />
                LOGIN parent
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="text-center btn-padding-login">
              <IonButton className="btn-ion" id="login-kid2" expand="block" onClick={() => dispatch(login("Grzegorz Brzęczyszczykiewicz", "kid2"))}>
                <FaChild />
                LOGIN kid2
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="text-center btn-padding-login">
              <IonButton className="btn-ion" id="login-terap1" expand="block" onClick={() => dispatch(login("Mściwoj Północny", "terap1"))}>
                <FaCommentMedical />
                LOGIN therapist
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </section>
    );

  }else{
    return(
      <section>
        <PinSelector title={t("login.pin")} value={typedPin} onChange={(v)=>setTypedPin([...typedPin, v])} randomize={true} error={error}/>
      </section>
    )
  }
}

export default Login;
