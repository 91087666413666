import React from 'react';
import classes from './SegmentBtn.module.scss';

const SegmentBtn = ({ labels, idx, setIdx }: Props) => {
    return (
        <div className={classes.Segment}>
            {labels.map((label, i) => (
                <button
                    key={i}
                    className={`${classes.SegmentButton} ${idx === i ? classes.active : ''}`}
                    onClick={() => setIdx(i)}
                >
                    {label}
                </button>
            ))}
        </div>
    );
};

interface Props {
    labels: string[];
    idx: number;
    setIdx: Function;
}

export default SegmentBtn;