import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { newHomework } from "../../05_redux/HomeworkSlice";
import store, { RootState } from "../../05_redux/store";
import { HomeworkEntity } from "../../06_utils/db/entity/homeworkEntity";
import { useSelector } from "react-redux";
import AddHomeworkManualy from "./AddHomeworkManualy";
import HomeworkDate from "./HomeworkDate";
import ImportHomework from "./ImportHomework";
import WizardPage from "../../02_molecule/WizardPage";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IonContent } from "@ionic/react";

const NewHomeWorkWizard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = store.dispatch;
  const selectedKid = useSelector(
    (root: RootState) => root.statusSlice.status.selectedKid
  );

  const [idx, setIdx] = useState(0);
  const [title, setTitle] = useState("");
  const [descr, setDescr] = useState("");
  const [homeworkDate, setHomeworkDate] = useState(
    new Date(Date.now() + 6.048e8)
  );
  const swiperRef = useRef(null);

  const saveEnable = title.length > 3;

  const save = () => {
    dispatch(
      newHomework({ title, descr, homeworkDate } as HomeworkEntity, selectedKid)
    );
    navigate("/homework");
  };

  const next = () => {
    if (idx !== pages.length - 1) {
      if (!swiperRef.current) return;
      swiperRef.current.swiper.slideNext();
      setIdx(idx + 1);
    }
  };

  const back = () => {
    if (idx !== 0) {
      if (!swiperRef.current) return;
      swiperRef.current.swiper.slidePrev();
      setIdx(idx - 1);
    }
  };

  const cancel = () => {
    navigate("/homework");
  };

  const pages = [
    { title: t("newHomework.importTitle"), content: <ImportHomework /> },
    {
      title: t("newHomework.manualTitle"),
      content: (
        <AddHomeworkManualy
          title={title}
          setTitle={setTitle}
          descr={descr}
          setDescr={setDescr}
        />
      ),
    },
    {
      title: t("newHomework.homeworkDate"),
      content: (
        <HomeworkDate
          homeworkDate={homeworkDate}
          setHomeworkDate={setHomeworkDate}
        />
      ),
    },
  ];

  return (
    <IonContent>
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setIdx(swiper.activeIndex)}
        initialSlide={idx}
      >
        {pages.map((p, i) => (
          <SwiperSlide key={i}>
            <WizardPage
              save={save}
              next={next}
              back={back}
              setPage={setIdx}
              saveEnable={saveEnable}
              cancel={cancel}
              wizardProgress={i / (pages.length - 1)}
              wizardMax={pages.length}
              title={p.title}
            >
              {p.content}
            </WizardPage>
          </SwiperSlide>
        ))}
      </Swiper>
    </IonContent>
  );
};

export default NewHomeWorkWizard;
