import React, { useMemo } from 'react';
import { FaCircle, FaRegCircle } from "react-icons/fa6";
import { IonGrid, IonRow, IonCol, IonButton, IonText } from '@ionic/react';
import classes from './PinSelector.module.scss';

const PinSelector = ({title, value, onChange, randomize, error}:Props) =>{
    const numBtns = useMemo(()=>randomize ? [0,1,2,3,4,5,6,7,8,9].sort(v=>.5 - Math.random()) : [0,1,2,3,4,5,6,7,8,9], [randomize]);

    const button = (index: number) => {
        const btnValue = numBtns[index];
        return (
            <IonButton
                id={`pin--btn-${btnValue}`}
                onClick={() => onChange(btnValue)}
                className={classes.btnIon}
                expand="block"
                size="small"
            >
                {btnValue}
            </IonButton>
        );
    };

    return(
        <div className="pinselector-container">
            <h1 className='font_roboto' style={{ fontSize: '28px' }}>{title}</h1>
            <div className='pinselector-value'>
                <IonRow>
                    <IonCol >{value[0] !== undefined ? <FaCircle/> : <FaRegCircle/>}</IonCol>
                    <IonCol >{value[1] !== undefined ? <FaCircle/> : <FaRegCircle/>}</IonCol>
                    <IonCol >{value[2] !== undefined ? <FaCircle/> : <FaRegCircle/>}</IonCol>
                    <IonCol >{value[3] !== undefined ? <FaCircle/> : <FaRegCircle/>}</IonCol>
                </IonRow>
            </div>
            {error?.length > 0 && (
                <IonText color="danger">
                <h5 className="error font_roboto" style={{ fontSize: '18px'}}>{error}</h5>
                </IonText>
            )}
            <div className="pinselector">
                <IonGrid>
                <IonRow>
                    <IonCol size="4">{button(1)}</IonCol>
                    <IonCol size="4">{button(2)}</IonCol>
                    <IonCol size="4">{button(3)}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="4">{button(4)}</IonCol>
                    <IonCol size="4">{button(5)}</IonCol>
                    <IonCol size="4">{button(6)}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="4">{button(7)}</IonCol>
                    <IonCol size="4">{button(8)}</IonCol>
                    <IonCol size="4">{button(9)}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="4"></IonCol>
                    <IonCol size="4">{button(0)}</IonCol>
                    <IonCol size="4"></IonCol>
                </IonRow>
                </IonGrid>
            </div>
        </div>
    )
}

interface Props{
    title: string;
    value: number[];
    onChange: Function;
    randomize: boolean;
    error: string;
}

export default PinSelector;