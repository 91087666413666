import React, { useState } from "react";
import { IonCard, IonRow, IonCol, IonButton, IonToggle } from "@ionic/react";
import "moment-timezone";
import store, { RootState } from "../../05_redux/store";
import { sharePost } from "../../05_redux/PostSlice";
import { FaHome, FaPlay } from "react-icons/fa";
import moment from "moment";
import { PostEntity } from "../../06_utils/db/entity/postEntity";
import { useSelector } from "react-redux";
import { FeelLikeIcon } from "../../01_atoms/FeelLike";
import { useNavigate } from "react-router-dom";
import classes from "./Post.module.scss";
import Emotion from "../../01_atoms/Emotion";

const Post = ({ post, editable, terapist }: Props) => {
  const status = useSelector((root: RootState) => root.statusSlice.status);
  const navigate = useNavigate();
  const dispatch = store.dispatch;
  const [showSentiment, setShowSentiment] = useState(false);

  const playRecord = (r) => {
    const audioRef = new Audio(r);
    audioRef.oncanplaythrough = () => audioRef.play();
    audioRef.load();
  };

  const shared = post.ACL && post.ACL["hzcEe6itaG"];

  const gotoDetail = (id: number) => {
    navigate("/post/" + id);
  };

  return (
    <IonCard className={classes.Post} onClick={() => gotoDetail(post.id)}>
      <IonRow>
        <IonCol size="12">
          <p className={classes.postDate}>{moment(post.postDate).format("YYYY-MM-DD HH:mm")}</p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="3">
          <IonRow>
            <FeelLikeIcon feelLike={post.feelLike} color={undefined} />
          </IonRow>
          <IonRow>
            {editable && status.isOnline && (
              <IonCol>
                <IonToggle checked={!!shared} onChange={() => dispatch(sharePost(post, !shared))} />
                <p className="sharedText">{shared ? "shared" : ""}</p>
              </IonCol>
            )}

            {terapist && post.sentiment && (
              <IonCol>
                <IonToggle checked={showSentiment} onChange={() => setShowSentiment(!showSentiment)} />
              </IonCol>
            )}
          </IonRow>
        </IonCol>
        <IonCol size="9">
          <IonRow>
            <p className={classes.postComment}>
              {post.comment.length > 130 ? post.comment.substring(0, 130) + "..." : post.comment}
            </p>
          </IonRow>
          <IonRow>
            {post.emotions?.map((e, i) => (
              <Emotion key={i} emotion={e} />
            ))}
          </IonRow>
          {(post.images?.length > 0 || post.records?.length > 0) && <hr />}
          <IonRow>
            {post.images?.map((e, i) => (
              <IonCol key={i}>
                <img src={e} height="64" width="64" alt={"image" + i} />
              </IonCol>
            ))}

            {post.records?.map((r, i) => (
              <IonCol key={i} className="text-center">
                <IonButton onClick={() => playRecord(r)}>
                  <FaPlay />
                </IonButton>
                <p className="recorTitle">record {i + 1}</p>
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            {post.homework && (
              <>
                <FaHome className={classes.HomeworkIcon} /> {post.homework.title}
              </>
            )}
          </IonRow>
        </IonCol>
      </IonRow>
      {showSentiment && (
        <IonRow>
          <IonCol>
            <hr />
            <pre>{post.sentiment}</pre>
          </IonCol>
        </IonRow>
      )}
    </IonCard>
  );
};

interface Props {
  post: PostEntity;
  editable: boolean;
  terapist?: boolean;
}

export default Post;
