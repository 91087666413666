import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import store, { RootState } from "../../05_redux/store";
import { HomeworkEntity } from "../../06_utils/db/entity/homeworkEntity ";
import classes from "./EndHomework.module.scss";
import Wave from "../../01_atoms/Wave";
import TopBar from "../../01_atoms/TopBar";
import Fab from "../../01_atoms/Fab";
import { FaFlagCheckered } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Rate from "../../01_atoms/Rate";
import { updateHomeworks } from "../../05_redux/HomeworkSlice";
import { IonCol, IonPage, IonRow } from "@ionic/react";

const EndHomework = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const homeworks: HomeworkEntity[] = useSelector(
        (root: RootState) => root.homeworkSlice.homeworkStore.homeworks
    );
    const { id } = useParams();
    const dispatch = store.dispatch;

    const homework = homeworks.find((h) => h.id === Number(id));

    const [rate, setRate] = useState(0);

    const back = () => {
        navigate("/homework/" + homework?.id);
    };

    const endHomework = () => {
        dispatch(updateHomeworks(homework!, true, rate)).then((res) => {
            navigate("/homework/");
        });
    };

    return (
        <IonPage>
            <TopBar fun={back} title={homework?.title as string} />
            <Wave invert />
            <div className={classes.EndHomework}>
                <IonRow>
                    <IonCol className={classes.EndHomeworkDescr}>
                        {homework?.descr}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Rate rate={rate} setRate={setRate} />
                    </IonCol>
                </IonRow>
                {/*<IonRow className={classes.HomeworkDetailPosts}>
                    <IonCol>
                        {homework?.post?.length === 0 && t("homeworkDetail.noPosts")}                    
                        {homework?.post?.map((pfh, i)=><Post key={i} post={pfh} editable={false}/>)}
                    </IonCol>
                </IonRow>*/}
                <IonRow className={classes.EndHomeworkButtons}>
                    <IonCol className={classes.EndHomeworkButton}>
                        <Fab
                            id="goto-add-post-from-homework--btn"
                            onClick={endHomework}
                            title={t("endHomework.closeHomework")}
                            floating={false}
                            disabled={rate === 0}
                        >
                            <FaFlagCheckered />
                        </Fab>
                    </IonCol>
                </IonRow>
            </div>
        </IonPage>
    );
};

export default EndHomework;
