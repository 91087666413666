import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../05_redux/store";
import { IonPage, IonRow, IonCol } from "@ionic/react";
import classes from "./PostDetail.module.scss";
import Wave from "../../01_atoms/Wave";
import TopBar from "../../01_atoms/TopBar";
import { PostEntity } from "../../06_utils/db/entity/postEntity";
import { useTranslation } from "react-i18next";
import { FeelLikeIcon } from "../../01_atoms/FeelLike";
import { FaEdit, FaRegEye } from "react-icons/fa";
import Fab from "../../01_atoms/Fab";
import Emotion from "../../01_atoms/Emotion";
import moment from "moment";

const PostDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const posts: PostEntity[] = useSelector((root: RootState) => root.postSlice.postStore.posts);
  const { id } = useParams();

  const post = posts.find((p) => p.id === Number(id))!;

  const back = () => {
    navigate("/");
  };

  const gotoHomework = (homeworkId) => {
    navigate("/homework/" + homeworkId);
  };

  const gotoEdit = (postId) => {
    navigate("/editPost/" + postId);
  };

  return (
    <IonPage>
      <TopBar fun={back} title={moment(post.postDate).format("YYYY-MM-DD HH:mm")} />
      <Wave invert />
      <div className={classes.PostDetail}>
        <IonRow>
          <IonCol className={classes.PostDetailDescr}>
            <FeelLikeIcon feelLike={post.feelLike} color={undefined} />
            {post?.comment}
          </IonCol>
        </IonRow>
        <IonRow className={classes.PostDetailEmotions}>
          {post.emotions?.map((e, i) => (
            <Emotion key={i} emotion={e} />
          ))}
        </IonRow>
        <IonRow className={classes.PostDetailButtons}>
          {post.homework && (
            <IonCol className={classes.PostDetailButton}>
              <Fab onClick={() => gotoHomework(post.homework?.id)} title={t("postDetail.checkHomework")} floating={false}>
                <FaRegEye />
              </Fab>
            </IonCol>
          )}
          <IonCol className={classes.PostDetailButton}>
            <Fab onClick={() => gotoEdit(post.id)} title={t("postDetail.editPost")} floating={false}>
              <FaEdit />
            </Fab>
          </IonCol>
        </IonRow>
      </div>
    </IonPage>
  );
};

export default PostDetail;
