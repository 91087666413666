import React from "react";
import classes from "./TopBarHomework.module.scss";
import { IonButton, IonGrid, IonRow, IonCol } from "@ionic/react";
import { FaArrowLeft } from "react-icons/fa6";

const TopBarHomework = ({ fun, title, icon, withBackground = true }: Props) => {
  return (
    <IonGrid className={[classes.TopBarContainer, withBackground && classes.TopBarContainerBackground].join(" ")}>
      <IonRow>
        <IonCol size="1">
          {fun && (
            <IonButton className={classes.BackBtn} fill="clear" onClick={() => fun()}>
              {icon ? icon : <FaArrowLeft />}
            </IonButton>
          )}
        </IonCol>

        <IonCol size="10" className={classes.Title}>
          {title}
        </IonCol>

        <IonCol size="1" />
      </IonRow>
    </IonGrid>
  );
};

interface Props {
  fun?: Function;
  title: string;
  icon: JSX.Element;
  withBackground: boolean;
}

export default TopBarHomework;
