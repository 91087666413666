import React from "react";
import { IonCard, IonCardTitle, IonCardContent } from "@ionic/react";
import 'moment-timezone';
import { FaRegCommentAlt, FaStar } from "react-icons/fa";
import { HomeworkEntity } from "../../06_utils/db/entity/homeworkEntity ";
import classes from './Homework.module.scss';
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Homework = ({ homework }: Props) => {
  const navigate = useNavigate();

  const gotoDetail = (id: number) => {
    navigate('/homework/' + id);
  };

  let tooltipColor = classes.ActiveHomework;
  if (homework.archived) {
    tooltipColor = classes.DoneHomework;
  } else if (new Date(homework.homeworkDate) < new Date()) {
    tooltipColor = classes.OvertimeHomework;
  }

  return (
    <IonCard onClick={() => gotoDetail(homework.id)} className={classes.HomeworkCard}>
      <div className={[classes.CornerTooltip, tooltipColor].join(' ')} />
      <div className={classes.CornerTooltipText}>
        {"do " + moment(homework.homeworkDate).format("MM-DD")}
      </div>
      
      
        <IonCardTitle className={classes.HomeworkTitle}>
          {homework.title}
        </IonCardTitle>
       
        <div className={classes.HomeworkMeta}>
          {homework.post && homework.post.length > 0 && (
            <span className={classes.HomeworkPostsCount}>
              {homework.post.length} <FaRegCommentAlt />
            </span>
          )}
          {homework.rating > 0 && (
            <span className={classes.HomeworkPostsCount}>
              {homework.rating} <FaStar />
            </span>
          )}
        </div>

      <IonCardContent>
        <div className={classes.HomeworkDescr}>
          {homework.descr.length > 130
            ? homework.descr.substring(0, 130) + "..."
            : homework.descr}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

interface Props {
  homework: HomeworkEntity;
}

export default Homework;
